import { constants as ethersConstants } from "ethers";

import { Chain, Env, Token } from "src/domain";

const selectTokenAddress = (token: Token, chain: Chain): string => {
  return token.wrappedToken && chain.chainId === token.wrappedToken.chainId
    ? token.wrappedToken.address
    : token.address;
};

const isTokenEther = (token: Token): boolean => {
  return token.address === ethersConstants.AddressZero;
};

const isTokenNative = (token: Token, chain: Chain, env: Env): boolean => {
  return (chain.key === "ethereum" && isTokenEther(token))
  || (chain.key === "polygon-zkevm" && env?.nativeGasToken.isEnabled && token.address === env.nativeGasToken.address)

}

export { isTokenEther, isTokenNative, selectTokenAddress };
